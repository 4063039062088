
  import {
    defineComponent,
    ref,
    inject,
    nextTick,
    watch,
    onUnmounted,
  } from "vue";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import Loading from "@/components/UI/Loading";
  import Swiper from "swiper";
  import asyncLoadJs from "@/hooks/useAsyncLoadJs";
  asyncLoadJs(
    "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
    "about"
  ).then(() => {
    asyncLoadJs(
      "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
      "about"
    );
  });
  import vTCPlayer from "@/hooks/useTcplayer";
  import Modal from "@/components/UI/Modal";
  export default defineComponent({
    name: "About",
    components: {
      Loading,
    },
    setup() {
      const data = ref(null);

      nextTick(() => {
        asyncLoadJs(
                "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
                "about"
        ).then(() => {
          asyncLoadJs(
                  "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
                  "about"
          ).then((res) => {
            vTCPlayer("player-container-id", "387702293727945337");
          });
        });
      })

      let Videoplayer: any = null;
      let VideoplayerContent: any = null;
      const videoFileid = ref(true);
      axios
              .get("/M/Home/About")
              .then((res) => {
                if (res.data.success) {
                  const kefulist = [];
                  const length = Math.ceil(res.data.obj.kefulist.length / 6);
                  for (let i = 0; i < length; i++) {
                    kefulist.push(res.data.obj.kefulist.slice(i * 6, i * 6 + 6));
                  }
                  data.value = { ...res.data.obj, kefulist };
                  nextTick(() => {
                    Videoplayer = vTCPlayer(
                            "player-container-id",
                            "387702293727945337"
                    );
                    VideoplayerContent = vTCPlayer(
                            "playerContentVideo",
                            "387702293727945337"
                    );
                  });
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log("err");
              });

      watch(data, () => {
        nextTick(() => {
          const swiper = new Swiper(".swiper-container", {
            initialSlide: 0,
            autoplay: true,
            loop: true,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            on: {
              observerUpdate: function () {
                this.slideTo(0);
              },
            },
            pagination: {
              el: ".swiper-pagination",
            },
          });
        });
      });

      onUnmounted(() => {
        // Videoplayer.dispose();
        // VideoplayerContent.dispose();
        videoFileid.value = false;
      });

      const loadOption = {
        text: "加载中...",
        color: "#b9135B",
        textColor: "#b9135B",
      };
      return {
        data,
        loadOption,
        videoFileid,
      };
    },
  });
