<template>
  <main v-if="data">
    <div class="banner">
      <div class="swiper-slide">
        <router-link
                :to="item.slideMLink"
                v-for="(item, index) in data.listimg"
                :key="index"
                :style="{ backgroundImage: `url(${item.slideImg})` }"
                :title="item.slideAlt"
                class="swiper-content"
        >
        </router-link>
      </div>
    </div>

    <div class="main-title title-p-sty">
      <p>北京普天同创生物科技有限公司</p>
    </div>
    <div class="position-info">
      <p style="text-indent: 2em;">北京普天同创生物科技有限公司，成立于2011年，是一家专注于标准物质和计量仪器领域的科研企业。成立至今，
        为深耕计量领域服务计量标准，普天同创始终坚持“科学严谨、用心服务”的经营理念，通过十多年的积累与沉淀，
        逐步实现集研发、生产、销售、服务为一体的专业供应链。公司拥有国内外数十万种标准物质、标准品等计量检测专用化学制剂，
        自主研发具有国内先进水平的精密计量器具，荣获多项专利证书。完备的标准物质保藏和管理系统，多系列产品定制服务，千万级仓储，
        快速响应式官网平台，闪电式发货，为国家检测机构、科研高校及企业提供精准化、权威化、专业化的优质服务，在业内得到广大用户的
        一致好评。服务科研，助力检测。</p>
      <p style="text-indent: 2em;">
        普天同创期待与您一同为中国计量标准检测事业的发展与进步共同奋斗。携手普天，同创辉煌！
      </p>
      <div class="video-wapper">
        <video
                id="player-container-id"
                v-if="videoFileid"
                preload="auto"
                style="width: 100%; height: 100%"
                playsinline
                webkit-playinline
                x5-playinline
                autoplay="autoplay"
        ></video>
      </div>
    </div>
    <div class="block">
      <div class="development-history-pt title-p-sty">
        <p>发展历程</p>
      </div>
      <div>
        <ul>
          <li>
            <img style="width:100%" src="https://yingzegbw.oss-cn-beijing.aliyuncs.com/common/corporation.jpg">
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="cor-friends title-p-sty">
        <p>合作伙伴</p>
      </div>
      <div>
        <ul>
          <li>
            <img style="width:100%" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/6fe42b2e7ed09921e0ccc1c766efe920.jpg">
          </li>
        </ul>
      </div>
      
    </div>
  </main>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
  import {
    defineComponent,
    ref,
    inject,
    nextTick,
    watch,
    onUnmounted,
  } from "vue";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import Loading from "@/components/UI/Loading";
  import Swiper from "swiper";
  import asyncLoadJs from "@/hooks/useAsyncLoadJs";
  asyncLoadJs(
    "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
    "about"
  ).then(() => {
    asyncLoadJs(
      "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
      "about"
    );
  });
  import vTCPlayer from "@/hooks/useTcplayer";
  import Modal from "@/components/UI/Modal";
  export default defineComponent({
    name: "About",
    components: {
      Loading,
    },
    setup() {
      const data = ref(null);

      nextTick(() => {
        asyncLoadJs(
                "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
                "about"
        ).then(() => {
          asyncLoadJs(
                  "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
                  "about"
          ).then((res) => {
            vTCPlayer("player-container-id", "387702293727945337");
          });
        });
      })

      let Videoplayer: any = null;
      let VideoplayerContent: any = null;
      const videoFileid = ref(true);
      axios
              .get("/M/Home/About")
              .then((res) => {
                if (res.data.success) {
                  const kefulist = [];
                  const length = Math.ceil(res.data.obj.kefulist.length / 6);
                  for (let i = 0; i < length; i++) {
                    kefulist.push(res.data.obj.kefulist.slice(i * 6, i * 6 + 6));
                  }
                  data.value = { ...res.data.obj, kefulist };
                  nextTick(() => {
                    Videoplayer = vTCPlayer(
                            "player-container-id",
                            "387702293727945337"
                    );
                    VideoplayerContent = vTCPlayer(
                            "playerContentVideo",
                            "387702293727945337"
                    );
                  });
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log("err");
              });

      watch(data, () => {
        nextTick(() => {
          const swiper = new Swiper(".swiper-container", {
            initialSlide: 0,
            autoplay: true,
            loop: true,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            on: {
              observerUpdate: function () {
                this.slideTo(0);
              },
            },
            pagination: {
              el: ".swiper-pagination",
            },
          });
        });
      });

      onUnmounted(() => {
        // Videoplayer.dispose();
        // VideoplayerContent.dispose();
        videoFileid.value = false;
      });

      const loadOption = {
        text: "加载中...",
        color: "#b9135B",
        textColor: "#b9135B",
      };
      return {
        data,
        loadOption,
        videoFileid,
      };
    },
  });
</script>


<style lang="scss" scoped>
  .banner {
    margin-bottom: 0.533rem;
  }
  .banner .swiper-content {
    width: 100%;
    height: 4rem;
    display: block;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
  }

  .banner .swiper-content img {
    /*width: 100%;*/
    height: 4rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }

  .banner .swiper-container {
    --swiper-theme-color: #fff;
    /* 设置Swiper风格 */
    --swiper-navigation-color: #fff;
    /* 单独设置按钮颜色 */
    --swiper-navigation-size: 1.067rem;
    /* 设置按钮大小 */
    --swiper-pagination-color: #b9135B;
    /* 两种都可以 */
  }

  .banner .my-bullet {
    display: inline-block;
    width: 0.347rem;
    height: 0.067rem;
    background-color: #ffffff;
    margin: 0 0.067rem;
    border-radius: 0;
    opacity: 1;
  }

  .banner .my-bullet-active {
    background-color: #b9135B;
  }

  .free-tel-model {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: none;
  }

  .free-tel-wapper {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    line-height: 1.5;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 0.267rem;
    z-index: 10001;
    display: none;
  }
  .free-tel-title {
    padding: 0.533rem 0.533rem 0.267rem;
    margin-bottom: -0.667rem;
    text-align: center;
    font-size: 0.467rem;
    font-weight: 400;
    color: #333;
  }

  #callMeTel {
    margin-left: 0.533rem;
    width: 87%;
    height: 1.067rem;
    line-height: 1.067rem;
    border: 0.027rem solid #c10728;
    padding-left: 0.267rem;
    outline: none;
  }

  .free-tel-btn {
    border-top: 0.027rem solid #eee;
    font-size: 0;
    margin-top: 0.533rem;
  }
  .free-tel-btn span {
    display: inline-block;
    height: 1.173rem;
    line-height: 1.173rem;
    color: #007aff;
    font-size: 0.453rem;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    width: 50%;
    text-align: center;
    zoom: 1;
    box-sizing: border-box;
  }

  .free-tel-btn .free-tel-cancel {
    color: #999;
  }
  .free-tel-confirm {
    border-left: 0.027rem solid #eee;
  }

  .free-tel-tip {
    padding: 0.533rem 0.533rem 0.267rem;
    font-size: 0.28rem;
  }

  main {
    padding-bottom: 1.333rem;
  }

  .main-title {
    width: 9.467rem;
    margin: 0 auto;
  }

  .main-title span {
    text-transform: uppercase;
    font-size: 10px;
    color: #666;
    font-weight: normal;
    position: relative;
    top: -5px;
  }

  .title-p-sty {
    font-size: 0.46rem;
    color: #B81459;
    font-weight: bold;
    padding-bottom:0.02rem;
    border-bottom: 0.027rem solid #eee;
  }
  .title-p-sty > p {
    line-height: 24px;
    padding-left: 16px;
    position: relative;
  }
  .title-p-sty > p::before {
    content: "";
    display: block;
    width: 4px;
    height: 23px;
    background-color: #B81459;
    position: absolute;
    top: 2px;
    left: 0;
  }
  .position-info {
    font-size: 0.36rem;
    color: #000;
    line-height: 0.64rem;
    padding: 0.4rem 0.267rem;
    width: 9.467rem;
    box-sizing: border-box;
    margin: 0 auto;
    padding-left: 0;
  }
  .position-info p {
    margin: 0;
  }
  .contract-wapper {
    margin-top: 0.533rem;
    padding-left: 0.267rem;
  }
  .contract-wapper a {
    display: inline-block;
    width: 3.733rem;
    height: 1.067rem;
    line-height: 1.067rem;
    text-align: left;
    font-size: 0.427rem;
    border: 0.027rem solid #e5e5e5;
    border-radius: 0.133rem;
    padding-left: 0.333rem;
    box-sizing: border-box;
    color: #666;
  }
  .contract-wapper .inline {
    margin-right: 0.8rem;
  }

  .contract-wapper img {
    width: 0.467rem;
    height: 0.48rem;
    vertical-align: middle;
    margin-right: 0.333rem;
  }

  .block {
    border-top: 0.16rem solid #eee;
    padding: 0 0.267rem;
    padding-top: 0.4rem;
    padding-bottom: 0.667rem;
  }

  .block-title {
    font-size: 0.46rem;
    color: #444444;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .block-title p {
    font-size: 10px;
    color: #666;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    position: relative;
    top: 2px;
  }

  .border-bottom-1px {
    border-bottom: 0.027rem solid #eee;
  }

  .custom-service {
    width: 9.467rem;
    margin: 0 auto;
    padding: 0.533rem 0;
  }
  .custom-service-ul {
    padding-bottom: 2.133rem;
    overflow: hidden;
  }
  .custom-service-ul li {
    float: left;
    width: 49%;
    padding-left: 1.52rem;
    margin: 0;
    margin-bottom: 5px;
    box-sizing: border-box;
    position: relative;
    font-size: 0.293rem;
    line-height: 0.48rem;
    color: #666;
  }

  .custom-service-ul li img {
    width: 1.253rem;
    height: 1.253rem;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .custom-service-ul li span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .account-info ul {
    padding: 0.733rem 0;
  }

  .account-info ul li {
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    padding: 0;
  }
  .account-info ul li:nth-child(1) {
    border-top: 1px solid #eee;
  }
  .account-info li img{
    font-size: 0;
    vertical-align: middle;
    width:33.33%;
    height:100%;
    /*height:56px;*/
  }
  .account-info li img:nth-child(1){
    border-right: 1px solid #eee;
  }
  .account-info li img:nth-child(2){
    border-right: 1px solid #eee;
  }

  .create-img {
    width: 100%;
    display: block;
  }

  .tip {
    width: 100%;
    height: 6.933rem;
    background-color: #b9135B;
    text-align: center;
    font-size: 0.533rem;
    color: #fff;
    padding-top: 2.667rem;
    box-sizing: border-box;
    font-weight: bold;
  }

  .tip p {
    font-size: 0.373rem;
    color: #fff;
    margin-top: 0.4rem;
    font-weight: normal;
  }

  .tip-container p {
    font-size: 0.4rem;
    color: #444444;
    line-height: 0.587rem;
    margin-top: 0.267rem;
    text-indent: 2em;
  }

  .tip-container a {
    color: #b9135B;
  }

  .development-history {
    border-top: 0.16rem solid #eee;

    padding: 0 0.267rem;
    padding-top: 0.667rem;
    padding-bottom: 1.333rem;
  }
  .dev-title {
    text-align: center;
    font-size: 0.46rem;
    font-weight: bold;
    color: #444444;
  }

  .dev-desc {
    font-size: 0.4rem;
    color: #444444;
    margin-top: 0.4rem;
    font-weight: bold;
    text-align: center;
  }

  .dev-list {
    font-weight: normal;
    font-size: 0.427rem;
    color: #444444;
    border-bottom: 0.027rem solid #eee;
  }

  .dev-list p {
    margin: 1em 0;
  }

  .video-wapper {
    width: 9.467rem;
    height: 5.333rem;
    margin: 0.4rem auto;
  }
</style>
